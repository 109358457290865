import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/Section"
import Location from "../components/Location"
import {
  MaxContainer,
  Row,
} from '../styles/utility'
 
const ThankYouPage = () => {
  return (
    <Layout>
      <SEO title="Thank you for your enquiry" noFollow={false} />
      <MaxContainer>
        <Row>
        <Section pageTitle="Thank you for your enquiry">
            <Location />
        </Section>
        </Row>
      </MaxContainer>
    </Layout>
  )
}

export default ThankYouPage
import * as React from "react"
import Section from "../Section"
import { 
    TwoColumnGrid,
    Column,
    ContentTitle,
  } from "../../styles/utility"
import { StaticImage } from "gatsby-plugin-image"


const Location = () => (
    <Section>
        <div id="trading-hours"/>
        <TwoColumnGrid>
        <Column>
            <a href="https://goo.gl/maps/Gg7kxoueMXNduTs77" target="_blank" rel="noopener noreferrer">
                <StaticImage 
                    src="../images/location-ndc.png" 
                    alt="General Dentistry"           
                    placeholder="blurred"
                />
            </a>
        </Column>
        <Column>
            <ContentTitle>Come Say Hello</ContentTitle>
            <p>Operating Hours: <br/>
                Mon - Fri: 9am - 4:30pm <br/>
                Wed: 2pm - 7pm <br/>
                Saturday: By appointment only <br/>
                Sunday: Closed 
                </p>
            <p>Address: <a href="https://goo.gl/maps/Gg7kxoueMXNduTs77" target="_blank" rel="noopener noreferrer">Suite 118 - Level 1, Building B 20 Lexington Drive, Bella Vista NSW 2153</a></p>
            <p>Email: <a href="mailto:info@norwestdentalcare.com.au">info@norwestdentalcare.com.au</a></p>
            <p> Phone: <a href="tel:1300886758">1300 886 758</a></p>
        </Column>
        </TwoColumnGrid>
    </Section>
)


export default Location
